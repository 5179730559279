import React, { createContext, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import addFbSrcipt from '../lib/add-fb-srcipt';
import { getCookie } from '../lib/cookies';
import fbEvent from '../lib/facebook-events';
import getUid from '../lib/get-uid';

interface Props { 
  children: ReactNode
}

interface FacebookEvent { 
  pixels: keyof typeof pixelsMap,
  event: string 
  options?: any
}

declare global {
  interface Window { 
    fbq: any
  }
}

interface FacebookTrackingContext {
  trackFacebook: (pixels: keyof typeof pixelsMap, name: string, opts?: any) => void
}

export const FacebookTrackingContext = createContext<FacebookTrackingContext>({ 
  trackFacebook: (pixels, name) => {}
});

const pixelsMap = { 
  b2b: [process.env.GATSBY_PUBLIC_B2B_PIXEL as string],
  candidates: [
    process.env.GATSBY_PUBLIC_CANDIDATES_1_PIXEL as string,
    process.env.GATSBY_PUBLIC_CANDIDATES_2_PIXEL as string,
  ]
}

const FacebookTrackingContextProvider: React.FC<Props> = props => {

  const { children } = props;

  const fbRequested = useRef<boolean>(false);

  const fbQueue = useRef<FacebookEvent[]>([]);

  const fbError = useRef<boolean>(false);

  const sendFbEvent = useCallback((pixel: keyof typeof pixelsMap, event: string, options?: any) => { 
    const uid = getUid();

    const time = Math.round(Date.now() / 1000);

    try { 
      const pixelIds = pixelsMap[pixel]; 

      pixelIds.forEach(pixelId => { 
        window.fbq(
          'trackSingle', 
          pixelId, 
          event, 
          options || {},
          { eventID: uid }
        )
      })

      setTimeout(() => fbEvent(pixel, event, {
        ...options,
        event_id: uid,
        timestamp_seconds: time,
      }), 500)
    } catch (e: any) {
      fbEvent(pixel, event, { 
        ...(options || {}),
        event_id: uid,
        timestamp_seconds: time,
      })
    }
  }, [])

  const handlePixelLoad = useCallback(() => {
    if(!window.fbq.getState) return;

    const { pixels } = window.fbq.getState();

    if (pixels.length < 3) return; 

    console.log(fbQueue.current.length)

    fbQueue.current.forEach(event => sendFbEvent(event.pixels, event.event, event.options));

    fbQueue.current = [];
  }, [
    fbQueue,
    sendFbEvent,
  ])

  const sendBeacons = useCallback(() => {
    if (!('sendBeacon' in navigator)) return; 

    if (!process.env.GATSBY_PUBLIC_GA4) return; 

    fbQueue.current.forEach(fbEvent => { 
      const { pixels, event, } = fbEvent;

      const params = new URLSearchParams({ 
        en: 'fb_event',
        'ep.custom_source': 'flatworld',
        'ep.event_id': getUid(),
        'ep.fb_pixel': pixels,
        'ep.fb_event_name': event,
        'ep.fbc': getCookie('_fbc'),
        'ep.fbp': getCookie('_fbp'),
        'ep.is_admin': getCookie('flatworld_admin') || process.env.GATSBY_PUBLIC_NODE_ENV || process.env.GATSBY_PUBLIC_IS_PREVIEW || '',
        'epn.timestamp_seconds': `${Math.round(Date.now() / 1000)}`,
      });

      navigator.sendBeacon(
        `https://metrics.flatworld.co/g/collect?tid=${process.env.GATSBY_PUBLIC_GA4}&v=2&richsstsse`, 
        params.toString()
      )
    });

    fbQueue.current = [];
  }, [fbQueue])

  const handlePageUnload = useCallback(() => {
    sendBeacons()
  }, [sendBeacons])

  const handlePageNotVisible = useCallback(() => { 
    if (document.visibilityState === 'hidden') {
      sendBeacons()
    }
  }, [sendBeacons])

  useEffect(() => { 
    window.addEventListener('visibilitychange', handlePageNotVisible);
    window.addEventListener('beforeunload', handlePageUnload);

    return () => { 
      window.removeEventListener('visibilitychange', handlePageNotVisible); 
      window.removeEventListener('beforeunload', handlePageUnload); 
    }
  }, [
    handlePageNotVisible,
    handlePageUnload
  ])

  const trackFacebook = useCallback((pixels: keyof typeof pixelsMap, event: string, options?: any) => {
    if(fbError.current) {
      fbEvent(pixels, event, { 
        ...(options || {}),
        timestamp_seconds: Math.round(Date.now() / 1000),
      });

      return;
    }

    if(
      window.fbq 
      && window.fbq.getState
      && window.fbq.getState().pixels.length === 3
    ) {
      sendFbEvent(pixels, event, options);

      return; 
    }

    if(!fbRequested.current) { 
      fbRequested.current = true; 

      addFbSrcipt(
        window, 
        document, 
        'script', 
        'https://connect.facebook.net/en_US/fbevents.js',
        () => window.fbq.addInitHandler(handlePixelLoad),
        () => {
          fbError.current = true;
          fbQueue.current.forEach(event => sendFbEvent(event.pixels, event.event, event.options));
          fbQueue.current = [];
        }
      )

      window.fbq.disablePushState = true;

      window.fbq('init', process.env.GATSBY_PUBLIC_B2B_PIXEL);
      window.fbq('addPixelId', process.env.GATSBY_PUBLIC_CANDIDATES_1_PIXEL);
      window.fbq('addPixelId', process.env.GATSBY_PUBLIC_CANDIDATES_2_PIXEL);
    }

    fbQueue.current.push({ pixels, event, options });
  }, [
    fbRequested,
    fbQueue,
    fbError,
  ])

  return (
    <FacebookTrackingContext.Provider value={{ trackFacebook }}>
      {children}
    </FacebookTrackingContext.Provider>
  );
}

export default FacebookTrackingContextProvider;