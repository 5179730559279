import React, { createContext, ReactNode, useContext, useEffect, useRef, useState, } from 'react';

declare global {
  interface Window { 
    dataLayer: {
      push: Function
    }
    gtag: Function
  }
}

const useGoogleTracking = () => {
  const fired = useRef<boolean>(false)

  const [ready, setReady] = useState(false);

  useEffect(
    () => {
      if (fired.current) return;

      window.dataLayer = window.dataLayer || [];

      window.gtag = function (){
        window.dataLayer.push(arguments);
      }

      window.gtag('js', new Date());

      if (process.env.GATSBY_PUBLIC_GA4)  { 
        window.gtag(
          'config', 
          process.env.GATSBY_PUBLIC_GA4, 
          {
            transport_url: 'https://metrics.flatworld.co',
            first_party_collection: true,
            send_page_view: false,
          }
        );
      }

      if (process.env.GATSBY_PUBLIC_UA) {
        window.gtag(
          'config', 
          process.env.GATSBY_PUBLIC_UA, 
          {
            transport_url: 'https://metrics.flatworld.co',
            first_party_collection: true,
            send_page_view: false,
          }
        );
      }

      if (process.env.GATSBY_PUBLIC_ADS) {
        window.gtag('config', process.env.GATSBY_PUBLIC_ADS);
      }

      const s = document.createElement('script');
      s.async = true;
      s.src = `https://scripts.flatworld.co/tagscript?id=${process.env.GATSBY_PUBLIC_GA4}`
      document.head.appendChild(s);

      setReady(true);
      fired.current = true;
    }, 
    [
      fired,
      setReady,
    ]
  );

  return ready; 
}

export default useGoogleTracking;