import React, { ReactNode, useCallback, useContext, useEffect, useRef } from 'react';
import { FacebookTrackingContext } from '../../contexts/FacebookTrackingContext';
import { HotjarContext } from '../../contexts/HotjarContext';
import { IntercomContext } from '../../contexts/IntercomContext';
import ga4Event from '../../lib/ga4-event';
import { uaPageView } from '../../lib/ua-event';
import { segmentPageEvent } from '../../lib/segment-events';
import linkedinEvent from '../../lib/linkedin-event';
import adsEvent from '../../lib/ads-event';
import useGoogleTracking from '../../hooks/useGoogleTracking';
import useLinkedinTracking from '../../hooks/useLinkedinTracking';

interface Props { 
  children: ReactNode
  pathname: string
}

const PageWrapper : React.FC<Props> = props => {

  const { children, pathname } = props;

  const { trackFacebook } = useContext(FacebookTrackingContext);

  const { loadHotjar } = useContext(HotjarContext);

  const { loadIntercom } = useContext(IntercomContext);

  const googleReady = useGoogleTracking();

  const linkedinReady = useLinkedinTracking();

  const lastPathname = useRef<string>('');

  const eagerLoadIntercom = useCallback(() => {
    loadIntercom();
    window.removeEventListener('scroll', eagerLoadIntercom);
  }, [loadIntercom])

  useEffect(() => {
    if (!googleReady || !linkedinReady) return;

    if(lastPathname.current === pathname) return; 

    lastPathname.current = pathname;

    ga4Event('page_view');
    uaPageView();
    segmentPageEvent();
    trackFacebook('candidates', 'Search'); 

    if (
      pathname.indexOf('/blog') === 0
    ) {
      trackFacebook('b2b', 'PageView'); 
      loadHotjar();
      linkedinEvent('pageview');
      adsEvent('pageview');
    }

    if (
      pathname.indexOf('/job') === 0
    ) {
      trackFacebook('candidates', 'PageView'); 
      setTimeout(loadIntercom, 30 * 1000);
      window.addEventListener('scroll', eagerLoadIntercom);
    }

    return () => { 
      window.removeEventListener('scroll', eagerLoadIntercom)
    }
  }, [
    googleReady,
    linkedinReady,
    trackFacebook,
    loadIntercom,
    eagerLoadIntercom,
    loadHotjar, 
    lastPathname, 
    pathname,
  ]);

  return (
    <>
      {children}
    </>
  );
}

export default PageWrapper;