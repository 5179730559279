import React, { useEffect, useRef, useState, } from 'react';

declare global {
  interface Window { 
    _linkedin_data_partner_ids: string[]
    lintrk: any
  }
}
const useLinkedinTracking = () => {
  const fired = useRef<boolean>(false);

  const [ready, setReady] = useState<boolean>(false);

  useEffect(
    () => {
      if (fired.current) return;

      const _linkedin_partner_id = process.env.GATSBY_PUBLIC_LINKEDIN as string;

      window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push(_linkedin_partner_id);

      window.lintrk = function(a: any,b: any){
        window.lintrk.q.push([a,b])
      };

      window.lintrk.q=[];

      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      document.head.appendChild(s)

      setReady(true);
      fired.current = true;
    }, 
    [
      fired,
      setReady,
    ]
  );

  return ready;
}

export default useLinkedinTracking;