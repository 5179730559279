import { useEffect, useState } from 'react';

const queryParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

export interface UseUtmsOptions {
  domain: string;
  prefix?: string;
  considerReferral?: boolean;
  fallbackPrefix?: string;
}

interface IUtms {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
}

const useUtms = (opts: UseUtmsOptions) => {
  const { prefix, considerReferral, domain, fallbackPrefix } = opts;

  const [ready, setReady] = useState(false);

  const [utms, setUtms] = useState<IUtms>({
    utm_source: '',
    utm_medium: '',
    utm_term: '',
    utm_campaign: '',
    utm_content: '',
  });

  useEffect(() => {
    try {
      const string = location.search?.substring(1);

      const search = string
        ? JSON.parse(
            '{"' +
              decodeURI(string).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
              '"}'
          )
        : {};

      const newUtms = queryParams.reduce((acc, param) => {
        const query = search[param] || '';

        const value =
          query ||
          getCookie(`${prefix || ''}${param}`) ||
          (fallbackPrefix ? getCookie(`${fallbackPrefix}${param}`) : '');

        acc[param] = value || '';

        return acc;
      }, {} as any);

      const referralCookie = getCookie('referral_source');

      const utmsWithReferral =
        considerReferral && referralCookie
          ? { ...newUtms, utm_source: 'referral', utm_medium: referralCookie }
          : newUtms;

      queryParams.forEach(param => {
        if (!utmsWithReferral[param]) return;

        setCookie({
          cname: `${prefix || ''}${param}`,
          cvalue: utmsWithReferral[param],
          exdays: 365,
          domain,
        });
      });

      if (
        considerReferral &&
        search['utm_source'] === 'referral' &&
        search['utm_medium'] &&
        !referralCookie
      ) {
        setCookie({
          cname: 'referral_source',
          cvalue: search['utm_medium'],
          exdays: 1095,
          domain,
        });
      }

      setUtms(utmsWithReferral);
      setReady(true);
    } catch (e) {
      console.log(e);
    }
  }, [prefix, considerReferral, domain, fallbackPrefix]);

  return { ready, utms };
};

export default useUtms;

interface SetCookieIptions {
  cname: string;
  cvalue: string;
  exdays: number;
  domain: string;
}

export function setCookie(opts: SetCookieIptions) {
  const { cname, cvalue, exdays, domain } = opts;

  const expires = new Date(Date.now() + exdays * 24 * 60 * 60 * 1000).toUTCString();

  document.cookie = `${cname}=${cvalue};domain=${domain};expires=${expires};path=/;SameSite=Strict`;
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}
