export default function ga4Event(name: string, opts?: any) {
  window.gtag('event', name, {
    send_to: process.env.GATSBY_PUBLIC_GA4,
    passthrough: true,
    custom_source: 'flatworld',
    is_admin:
      process.env.GATSBY_PUBLIC_NODE_ENV === 'development' ||
      process.env.GATSBY_PUBLIC_IS_PREVIEW === 'true',
    ...(opts || {}),
  });
}
