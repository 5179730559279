import React, { ReactNode } from 'react';
import FacebookTrackingContextProvider from '../../contexts/FacebookTrackingContext';
import HotjarContextProvider from '../../contexts/HotjarContext';
import IntercomContextProvider from '../../contexts/IntercomContext';
import useGoogleTracking from '../../hooks/useGoogleTracking';
import useLinkedinTracking from '../../hooks/useLinkedinTracking';

interface Props { 
  children: ReactNode
}

const TrackingWrapper : React.FC<Props> = props => {

  const { children } = props;

  return (
    <IntercomContextProvider> 
      <FacebookTrackingContextProvider>
        <HotjarContextProvider>
          {children}
        </HotjarContextProvider>
      </FacebookTrackingContextProvider>
    </IntercomContextProvider>
  );
}

export default TrackingWrapper;