import { getCookie, setCookie } from './cookies';
import getUid from './get-uid';

export function segmentPageEvent() {
  segmentEvent('segment_page_event');
}

interface SegmentEventOptions {
  uid: string;
  email: string;
  job: string;
}

export function segmentIdentifyEvent(options: SegmentEventOptions) {
  const { uid, email, job } = options;

  segmentEvent('segment_identify_event', {
    segment_uid: uid,
    segment_email: email,
    segment_job: job,
  });
}

interface SegmentTrackEventOptions extends SegmentEventOptions {
  event: string;
}

export function segmentTrackEvents(options: SegmentTrackEventOptions) {
  const { event, uid, email, job } = options;

  segmentEvent('segment_track_event', {
    segment_event_name: event,
    segment_uid: uid,
    segment_email: email,
    segment_job: job,
  });
}

function segmentEvent(name: string, opts?: any) {
  const anonymousId = getOrCreateUid();

  window.gtag('event', name, {
    send_to: process.env.GATSBY_PUBLIC_GA4,
    custom_source: 'flatworld',
    is_admin:
      process.env.GATSBY_PUBLIC_NODE_ENV === 'development' ||
      process.env.GATSBY_PUBLIC_IS_PREVIEW === 'true',
    segment_anonymous_id: anonymousId,
    pathname: window.location.pathname,
    search: window.location.search,
    ...(opts || {}),
  });
}

function getOrCreateUid() {
  const anonymousId = getCookie('ajs_anonymous_id') || getUid();

  setCookie({
    name: 'ajs_anonymous_id',
    value: anonymousId,
    days: 1080,
    domain: process.env.GATSBY_PUBLIC_DOMAIN as string,
  });

  return anonymousId;
}
