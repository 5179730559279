interface SetCookieIptions {
  name: string;
  value: string;
  days: number;
  domain: string;
}

export function setCookie(opts: SetCookieIptions) {
  const { name, value, days, domain } = opts;

  const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();

  document.cookie = `${name}=${value};domain=${domain};expires=${expires};path=/;SameSite=Strict`;
}

export function getCookie(cname: string) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}
