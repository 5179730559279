import React, { createContext, ReactNode, useCallback, useRef } from 'react';

interface Props { 
  children: ReactNode
}

interface IntercomContext { 
  loadIntercom: () => void,
}

export const IntercomContext = createContext({ 
  loadIntercom: () => {},
})

declare global {
  interface Window { 
    intercomSettings: any
    Intercom: any
  }
}

const IntercomContextProvider : React.FC<Props> = props => {

  const { children } = props;

  const fired = useRef<boolean>(false);

  const loadIntercom = useCallback(() => { 
    if (fired.current) return; 

    fired.current = true;

    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "gygbs1lj"
    };

    const s = document.createElement('script');
    s.type = 'text/javascript';s.async=true;
    s.src = 'https://widget.intercom.io/widget/gygbs1lj';

    document.head.appendChild(s);
  }, [fired]);

  return (
    <IntercomContext.Provider value={{ loadIntercom }}>
      {children}
    </IntercomContext.Provider>
  );
}

export default IntercomContextProvider;