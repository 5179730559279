/* eslint-disable */
import React from 'react';
import UtmContextProvider from './src/contexts/UtmContext'
import PageWrapper from './src/components/PageWrapper';
import TrackingWrapper from './src/components/TrackingWrapper';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <UtmContextProvider>
      <TrackingWrapper>
        {element} 
      </TrackingWrapper>
    </UtmContextProvider>
  );
}

export const wrapPageElement = ({ element, props }) => { 
  const { location: { pathname } } = props;

  return (
    <PageWrapper pathname={pathname}>
      {element}
    </PageWrapper>
  );
}