import { IAEvent } from '../common/aEvent';

export default function uaEvent(event: IAEvent, opts?: any) {
  const { ea, ec, el, ev, ni } = event;

  window.gtag('event', ea, {
    event_category: ec,
    event_label: el,
    value: ev,
    non_interaction: ni,
    send_to: process.env.GATSBY_PUBLIC_UA,
    custom_source: 'flatworld',
    is_admin:
      process.env.GATSBY_PUBLIC_NODE_ENV === 'development' ||
      process.env.GATSBY_PUBLIC_IS_PREVIEW === 'true',
    ...(opts || {}),
  });
}

export function uaPageView() {
  window.gtag('event', 'page_view', {
    page_title: document.title,
    page_location: window.location.href,
    page_path: `${window.location.pathname}${window.location.search}`,
    page: {
      path: `${window.location.pathname}`,
    },
    send_to: process.env.GATSBY_PUBLIC_UA,
  });
}
