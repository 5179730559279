import React, { createContext } from "react";
import useUtms from "../hooks/useUtms";

type ITrackingContext = ReturnType<typeof useUtms>;

export const UtmContext = createContext<ITrackingContext>(null as any);

interface Props {}

const UtmContextProvider: React.FC<Props> = (props) => {
  const { children } = props;

  const utms = useUtms({
    domain: (process.env.GATSBY_PUBLIC_DOMAIN as string).split(':')[0], 
    considerReferral: true,
  });

  return (
    <UtmContext.Provider value={utms}>
      {children}
    </UtmContext.Provider>
  );
};

export default UtmContextProvider;
