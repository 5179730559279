import React, { createContext, useCallback, useRef } from 'react';

interface Props { 

}

interface HotjarContext { 
  loadHotjar: () => void
}

export const HotjarContext = createContext({ 
  loadHotjar: () => {}
})

const HotjarContextProvider : React.FC<Props> = props => {

  const { children } = props;

  const fired = useRef<boolean>(false);

  const loadHotjar = useCallback(() => { 
    if(fired.current) return; 

    const script = document.createElement('script');
    script.innerHTML = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2177531,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `
    document.head.appendChild(script);
    fired.current = true;
  }, [fired])

  return (
    <HotjarContext.Provider value={{ loadHotjar }}>
      {children}
    </HotjarContext.Provider>
  );
}

export default HotjarContextProvider;