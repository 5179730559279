export default function(f,b,e,v,ready,error){
  let n,t,s;

  if(f.fbq)return;
  n=f.fbq=function(){
    n.callMethod
      ? n.callMethod.apply(n,arguments)
      : n.queue.push(arguments)
  };
  if (!f._fbq) f._fbq=n;
  n.push=n;
  n.loaded=!0;
  n.version='2.0';
  n.queue=[];
  t=b.createElement(e);
  t.async=!0;
  t.src=v;
  t.onload=ready;
  t.onerror=error;
  s=b.getElementsByTagName(e)[0]; 
  setTimeout(() => s.parentNode.insertBefore(t,s), 10 * 1000);
}