import { getCookie } from './cookies';

export default function fbEvent(pixel: string, name: string, opts?: any) {
  window.gtag('event', 'fb_event', {
    send_to: process.env.GATSBY_PUBLIC_GA4,
    custom_source: 'flatworld',
    fb_event_name: name,
    fb_pixel: pixel,
    fbc: getCookie('_fbc'),
    fbp: getCookie('_fbp'),
    is_admin:
      process.env.GATSBY_PUBLIC_NODE_ENV === 'development' ||
      process.env.GATSBY_PUBLIC_IS_PREVIEW === 'true',
    ...(opts || {}),
  });
}
