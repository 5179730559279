const conversions =
  process.env.GATSBY_PUBLIC_NODE_ENV === 'development' ||
  process.env.GATSBY_PUBLIC_IS_PREVIEW === 'true'
    ? {
        pageview: 11073921,
        formview: 11073897,
        formstart: 11073905,
        lead: 11073913,
        toggl: 11073889,
      }
    : {
        pageview: 9124234,
        formview: 9124226,
        formstart: 9845089,
        lead: 9124210,
        toggl: 3592305,
      };

export default function linkedinEvent(event: keyof typeof conversions) {
  window.lintrk('track', { conversion_id: conversions[event] });
}
