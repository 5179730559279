const conversions =
  process.env.GATSBY_PUBLIC_NODE_ENV === 'development' ||
  process.env.GATSBY_PUBLIC_IS_PREVIEW === 'true'
    ? {
        pageview: 'AW-10962418613/WBR7CLmH9IIYELX3pOso',
        candidatesClick: 'AW-10962418613/v6x2CPbX6YIYELX3pOso',
        lead: 'AW-10962418613/kaCsCIbFp4EYELX3pOso',
        formstart: 'AW-10962418613/3CTECJbDp4EYELX3pOso',
        formview: 'AW-10962418613/A_bxCNv6pIEYELX3pOso',
      }
    : {
        pageview: 'AW-652107401/XosjCPuN84IYEIm9-bYC',
        candidatesClick: 'AW-652107401/kDWGCND1n4IYEIm9-bYC',
        lead: 'AW-652107401/XVjGCPqS3dEDEIm9-bYC',
        formstart: 'AW-652107401/1wq4COPxodIDEIm9-bYC',
        formview: 'AW-652107401/9ihjCIXgmNIDEIm9-bYC',
      };

export default function adsEvent(key: keyof typeof conversions) {
  window.gtag('event', 'conversion', {
    send_to: conversions[key],
  });
}
